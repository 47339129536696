<template>
  <card class="mx-auto my-20">
    <template #title>Login</template>
    <template>
      <o-auth />

      <hr class="" />
      <form class="mx-2 flex flex-col gap-2 justify-center">
        <form-input
          id="email"
          type="email"
          placeholder="Email"
          label="Email"
          v-model="email"
        />
        <div v-if="errors && errors['email']">
          <p
            v-for="(value, index) in errors['email']"
            :key="index"
            class="text-red-500 text-xs"
          >
            {{ value }}
          </p>
        </div>
        <form-input
          id="password"
          type="password"
          placeholder="Password"
          label="Password"
          v-model="password"
        />
      </form>
      <div v-if="errors && errors['password']">
        <p
          v-for="(value, index) in errors['password']"
          :key="index"
          class="text-red-500 text-xs"
        >
          {{ value }}
        </p>
      </div>
      <div v-if="errors && errors.detail">
        <p class="text-red-500 text-xs">
          {{ errors.detail }}
        </p>
      </div>
      <div class="mx-16 mt-6">
        <t-button
          primary
          :loading="isLoading"
          class="mx-auto w-28 h-7"
          @click.native="tryLogin()"
          >Signin</t-button
        >
      </div>
      <div class="flex flex-row items-center mx-auto">
        <t-button link tag="router-link" to="/signup"
          >Create an account</t-button
        >
        <p class="text-xs">|</p>
        <t-button link tag="router-link" to="/recover"
          >Forgot Password</t-button
        >
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Card.vue";
import FormInput from "@/components/core/FormInput.vue";
import TButton from "@/components/core/Button.vue";
import OAuth from "./oauth2/Index.vue";

import { mapGetters } from "vuex";

export default {
  name: "Signin",
  components: {
    Card,
    FormInput,
    TButton,
    OAuth,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async tryLogin() {
      if (this.fieldsNotEmpty) {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });

        await this.goHome();
      } else {
        await this.$store.commit("auth/SET_ERRORS", {
          detail: "None of the fields could be empty",
        });
      }
    },
    goHome() {
      if (!this.errors) {
        this.$router.push("/");
      }
    },
  },
  computed: {
    ...mapGetters({
      errors: "auth/errors",
      isLoading: "auth/isLoading",
    }),

    fieldsNotEmpty() {
      return this.email.length > 0 && this.password.length > 0;
    },
  },
  beforeDestroy() {
    this.$store.commit("auth/SET_ERRORS", null);
  },
};
</script>
