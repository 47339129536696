<template>
  <div class="block w-full">
    <label :for="id" class="text-black text-xs font-semibold">{{ label }}</label>
    <base-input
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
    ></base-input>
  </div>
</template>

<script>
import BaseInput from "./BaseInput.vue";

export default {
  name: "FormInput",
  components: {
    BaseInput,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>
