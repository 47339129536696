<template>
  <div class="w-72">
    <p class="text-2xl font-bold text-center text-green mb-4">
      <slot name="title"></slot>
    </p>
    <div class="bg-white rounded-xl">
      <div class="flex flex-col gap-2 py-4 mx-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>
