<template>
  <input
    :type="type"
    min="0"
    :placeholder="placeholder"
    :value="value"
    @input="handleInput($event)"
    class="block w-full py-1 pl-2 transition duration-100 ease-in-out text-sm text-black bg-gray-100 rounded focus:outline-none ring-0 focus:ring-1 focus:ring-gray-300"
  />
</template>

<script>
export default {
  name: "BaseInput",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String | Number,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleInput(e) {
      if (this.type === "number") {
        this.$emit("input", Number(e.target.value));
      } else {
        this.$emit("input", e.target.value);
      }
    },
  },
};
</script>
