<template>
  <div class="mx-8 py-2 flex flex-col gap-3 justify-center">
    <button
      class="w-full bg-red-600 text-white text-sm font-bold tracking-wide text-center px-4 py-1"
      @click="withGoogle()"
    >
      Sign in with Google
    </button>
    <button
      class="w-full bg-blue-500 text-white text-sm font-bold tracking-wide text-center px-4 py-1"
      @click="withFacebook()"
    >
      Singin with Facebook
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "oauth",
  methods: {
    withGoogle() {
      this.$store.dispatch("auth/getUser").then(() => {
        window.location.href = this.user.oauth2.google;
      });
    },
    withFacebook() {
      this.$store.dispatch("auth/getUser").then(() => {
        window.location.href = this.user.oauth2.facebook;
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/currentUser",
      errors: "auth/errors",
    }),
  },
};
</script>
